$('#register_form input').on('input' ,function() {
    if ($(this).attr('name') === "password"|| $(this).attr('name') ==="promo_code") return;

    let inputValue = $(this).val();
    parentForm = $(this).parents('form');

    if ($(this).attr('name') === "email") {
        let regex = /^\S+@\S+\.\S+$/;

        if (regex.test(inputValue)) {
            $(this).removeClass('border-danger');
        } else {
            $(this).addClass('border-danger');
        }
    } else if ($(this).attr('name') === "phone") {
        let regex = /^\d{3}-\d{3}-\d{4}$/;

        if (inputValue.length > 12) {
            inputValue = inputValue.slice(0, -1);
        }

        if (regex.test(inputValue)) {
            $(this).removeClass('border-danger');
        } else {
            $(this).addClass('border-danger');
        }
    }else if($(this).attr('name') === "promo_code"){
    } else {
        if (inputValue !== "" || $(this).attr('name') === "promo_code") {
            $(this).removeClass('border-danger');
        } else {
            $(this).addClass('border-danger');
        }
    }

    let submitButton = $(this).parents('form').find("button[type='submit']");

    if ($(parentForm).find('#password_requirements .fa-check-circle').not('.d-none').length === 3
        && $(parentForm).find('input.border-danger').length === 0
        && checkEmptyInputs()) {

        submitButton.attr('disabled', false);
    } else {
        submitButton.attr('disabled', true);
    }
});

function checkEmptyInputs() {
    if (first_name.value !== ""
        && last_name.value !== ""
        && email.value !== ""
        && phone.value !== "") {

        return true;
    } else {
        return false;
    }
}
